import { debounce } from 'lodash'
import { handleize } from './utils'

export const handleAddToCartClick = (product, index, parameter, variant) => {
  let firstVariant = variant ? variant : product.variants[0]
  let item_id = firstVariant.sku ? firstVariant.sku : firstVariant.id
  let items = [{
    item_id: item_id,
    item_name: product.title,
    index: index,
    item_brand: product.vendor,
    item_category: product.productCollections,
    item_variant: firstVariant.title,
    price: (parseFloat(firstVariant.price) / 100).toFixed(2).toString(),
    quantity: 1,
    item_list_id: product.item_list_id,
    item_list_name: product.item_list_name
  }]

  window.dataLayer.push({
    'event':'add_to_cart',
    parameter_1: parameter,
    ecommerce: {
      items: items
    }
  })
}

export const getCategory = (product) => {
  let obj = {}
  product.productCollections.forEach((item, index) => {
    if (index === 0) {
      obj['item_category'] = item
    } else if (index < 5) {
      obj[`item_category${index + 1}`] = item
    }
  })
  return obj
}

export function pushFormSubmit(form_name) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event' : 'form_submission',
    params: {
      form_name: form_name,
      subject: "newsletter signup"
    }
  })
}

export function pushSelectProductItem (productData) {
  window.dataLayer = window.dataLayer || []
  dataLayer.push({
    event: "select_item",
    ecommerce: {
      items: [].concat(productData)
    }
  })
}

export function observerProductCards(productCards){
  productCards.forEach(item => {
    const cardPosition = item.querySelector('.js-position-card')
    if (!cardPosition) return
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        item.classList.add('in-viewport')
      } else {
        item.classList.remove('in-viewport')
      }
    }, {})
    observer.observe(cardPosition)
  })
}

export function getProductData (product) {
  const productData = {
    item_id: product.variants[0].sku,
    item_name: product.title.replace("'", ''),
    index: parseInt(product.index),
    item_brand: product.vendor,
    item_list_id: product.item_list_id || "featured_products",
    item_list_name: product.item_list_name || "Featured Products",
    item_variant: product.variants[0].title,
    price: (product.price / 1).toFixed(2).toString(),
    currency: Shopify.currency.active,
    quantity: product.datalayer_quantity || 1
  }

  if (product.productCollections && product.productCollections.length) {
    productData['item_category'] = product.productCollections.toString()
  }

  return productData
}

export const scrollHandle = debounce(() => {
  handleListProductInViewport()
}, 200, { leading: false, trailing: true })

export const handleListProductInViewport = () => {
  const products = document.querySelectorAll('.in-viewport')
  let data = []
  products.forEach(item => {
    data.push(item)
  })

  const listId = data.map(item => item.id)
  const sessionData = JSON.parse(sessionStorage.getItem('data-layer')) || []

  const sameIds = listId.length === sessionData.length && listId.every(function(value, index) { return value === sessionData[index]})
  if (!sameIds) {
    const viewItemList = []
    data.forEach(item => {
      const { datalayerIndex, datalayerPlacement, datalayerProduct } = item.dataset
      const data = JSON.parse(decodeURIComponent(datalayerProduct))
      data.item_list_id = handleize(datalayerPlacement)
      data.item_list_name = datalayerPlacement
      data.index = datalayerIndex

      viewItemList.push(getProductData(data))
    })

    pushViewItemListToDataLayer(viewItemList)
    sessionStorage.setItem('data-layer', JSON.stringify(listId))
  }
}
export function pushViewItemListToDataLayer (viewItemList) {
  if (!viewItemList || !viewItemList.length) return
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      items: viewItemList
    }
  });
}